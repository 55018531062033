import React from 'react';

import Container from '../components/Container';
import Text from '../components/Text';

const NotFound = () => (
  <Container>
    <Text as="h1" size="x-large">
      Page not found.
    </Text>
  </Container>
);

export default NotFound;
